<template>
  <div>
    <label
      :class="classLabel"
      :for="name"
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >*</span>
    </label>
    <div>
      <b-input-group>
        <b-input-group-prepend
          v-if="prependIcon"
          is-text
        >
          <feather-icon :icon="prependIcon" />
        </b-input-group-prepend>
        <b-form-input
          v-model="model"
          :value="value"
          :type="type"
          :name="name"
          :placeholder="placeholder"
          :required="required"
          class="custom__input"
          :disabled="disabled"
          @focus="onFocus"
          @input="handleInput"
        />
        <b-input-group-append
          v-if="appendIcon"
          is-text
        >
          <feather-icon :icon="appendIcon" />
        </b-input-group-append>
      </b-input-group>
      <small
        v-if="message"
        class="text-danger"
      >{{ message }}</small>
      <!-- <small
        v-if="messages.address && Array.isArray(messages.address) && messages.address.length > 0"
        class="text-error"
      >{{ messages.address.join(', ') }}</small> -->
      <!-- <small>{{ instruction }}</small> -->
    </div>
  </div>
</template>

<script>
import {
  BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  components: {
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
  },
  props: {
    name: {
      type: String,
      default() {
        throw new Error('No form field name specified.')
      },
    },
    type: {
      type: String,
      default() {
        throw new Error('No form field type specified.')
      },
    },
    value: {
      type: [String, Boolean, Number],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    classLabel: {
      type: String,
      default: null,
    },
    instruction: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: null,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    appendIcon: {
      type: String,
      default: null,
    },
    onFocus: {
      type: Function,
      default() {},
    },
    handleInput: {
      type: Function,
      default() {},
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    // handleInput(e) {
    //   this.$emit('input', e.target.value)
    // },
  },
}
</script>
