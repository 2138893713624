<template>
    <div class="section">
        <header-page />
        <TableGudang :get-data="getData" :result="result" :get-detail="getDetail" :detail-result="detailResult" @filterBranch="getFilter" />
        <ModalGudang :get-data="getData" :result="result" :get-detail="getDetail" :detail-result="detailResult" />
    </div>
</template>

<script>
import TableGudang from '@/components/Gudang/TableGudang.vue';
import ModalGudang from '@/components/Gudang/Modal/ModalGudang.vue';
import HeaderPage from '@/components/HeaderPage.vue'

export default {
    title() {
        return 'Gudang'
    },
    components: {
        HeaderPage,
        TableGudang,
        ModalGudang
    },
    data() {
        return {
            filter: {
                search: '',
                per_page: 10,
                sort_type: 'asc',
            },
            currentPage: 1,
            isLoading: true,
            result: {},
            detailResult: {},
            validations: [],
        }
    },
    created() {
        this.getData();
    },
    methods: {
        getFilter(value) {
            this.filter = value
        },
        async getData(page = 1) {
            this.isLoading = true
            const queryParams = this.filter
            queryParams.page = page
            this.$store.dispatch('warehouse/getData', { uuid: '', params: queryParams }).then(result => {
                this.isLoading = false
                this.result = result.data.data
                this.currentPage = result.data.data.current_page
            }).catch(err => {
                // eslint-disable-next-line no-console
                console.log(err)
            })
        },
        getDetail(id) {
            this.$store.dispatch('warehouse/getDetail', {
                uuid: id || '',
                params: '',
            }).then(result => {
                this.detailResult = result.data.data
            }).catch(err => {
                // eslint-disable-next-line no-console
                console.log(err)
            })
        },
    },
}
</script>