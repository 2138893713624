<template>
  <b-modal id="modal-gudang" hide-header hide-footer ok-only ok-title="Accept" centered size="lg" title="Large Modal"
    class="custom__modal--large">
    <div class="section">
      <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
        <b-row>
          <b-col cols="3" class="d-flex align-items-center">
            <div style="cursor: pointer;" @click="directPage">
              <feather-icon size="24" class="text-dark font-weight-bolder" icon="ArrowLeftIcon" />
              <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
            </div>
          </b-col>
          <b-col cols="6" class="d-flex justify-content-center align-items-center">
            <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
              Detail Gudang
            </h1>
          </b-col>
          <!-- <b-col cols="3" class="text-right">
            <b-button class="px-3" @click="!editUuid ? createItem() : updateItem()">
              Simpan
            </b-button>
          </b-col> -->
        </b-row>
      </header>
      <b-container class="mt-5 pt-5">
        <b-row class="justify-content-center h-100 bg-white pb-5">
          <b-col cols="12" md="5">
            <div class="mb-2">
              <label>Nama Gudang</label>
              <b-col cols="12" class="custom__input mb-2 pt-1">
                {{ detailResult.name }}
              </b-col>
            </div>

            <div class="mb-2">
              <label>Alamat Gudang</label>
              <b-col cols="12" class="custom__input mb-2 pt-1">
                {{ detailResult.address }}
              </b-col>
            </div>

            <div class="mb-2">
              <label>Kota</label>
              <b-col cols="12" class="custom__input mb-2 pt-1">
                {{ detailResult.city }}
              </b-col>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import {
  BModal, BContainer, BRow, BCol, BButton,
} from 'bootstrap-vue'

import moment from 'moment'
import BaseInput from '@/components/BaseInput.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BContainer,
    BaseInput,
  },
  props: {
    getData: {
      type: Function
    },
    result: {
      type: Object
    },
    getDetail: {
      type: Function
    },
    detailResult: {
      type: Object
    },
  },
  methods: {
    getPhoto(value) {
      this.formPayload.logo = value.photo
    },
    directPage() {
      //   this.cleanUpForm()
      this.$bvModal.hide('modal-gudang')
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

label {
  font-size: 14px;
  font-weight: 600;
  color: #2B324F;
}

.custom__input {
  height: 52px;
  background: #EFF1F5;
  border: 1px solid #E4E5EC;
  border-radius: 8px;
  color: #2B324F;
  font-weight: 600;
  font-size: 14px;
}
</style>