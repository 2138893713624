<template>
  <header class="w-100">
    <!-- border-bottom border-right border-right-light border-right-line -->
    <b-container
      fluid
      class="w-100 bg-light bg-background d-flex align-items-center border-0"
      style="height: 83.19px;"
    >
      <div class="d-flex justify-content-between w-100 align-items-center">
        <h1
          class="h6 fw-bold-700 mb-0 w-100"
          :class="className"
        >
          <slot />
        </h1>
        <slot name="action" />
      </div>
    </b-container>
  </header>
</template>

<script>
import { BContainer } from 'bootstrap-vue'

export default {
  components: {
    BContainer,
  },
  props: {
    className: {
      type: String,
      default: 'size16 ml-2',
    },
  },
}
</script>
